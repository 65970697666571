<template>
  <div id="login">
    <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>
    <section>
      <div class="col1">
        <h1>RUTA 3:18</h1>
        <p>Queridos hijos, que nuestro amor no quede solo en palabras; mostremos la verdad por medio de nuestras acciones. <br />1 Juan 3:18</p>
      </div>
      <div :class="{ 'signup-form': !showLoginForm }" class="col2">
        <form v-if="showLoginForm" @submit.prevent>
          <h1>Bienvenido</h1>
          <div>
            <label for="email1">Email</label>
            <input v-model.trim="loginForm.email" type="text" placeholder="tu@email.com" id="email1" />
          </div>
          <div>
            <label for="password1">Contraseña</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" />
          </div>
          <button @click="login()" class="button">Entrar</button>
          <div class="extras">
            <a @click="togglePasswordReset()">Recuperar contraseña</a>
            <a @click="toggleForm()">Crear cuenta</a>
          </div>
        </form>
        <form v-else @submit.prevent>
          <h1>Regístrate</h1>
          <div>
            <label for="name">Nombre</label>
            <input v-model.trim="signupForm.name" type="text" placeholder="Nombre" id="name" />
          </div>
          <div>
            <label for="title">Apellido</label>
            <input v-model.trim="signupForm.lastName" type="text" placeholder="Apellido" id="lastName" />
          </div>
          <div>
            <label for="email2">Email</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="tu@email.com" id="email2" />
          </div>
          <div>
            <label for="password2">Contraseña</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="min 6 caracteres" id="password2" />
          </div>
          <button @click="signup()" class="button">Registrarme</button>
          <div class="extras">
            <a @click="toggleForm()">Atrás</a>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import PasswordReset from '@/components/PasswordReset'

export default {
  components: {
    PasswordReset
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        name: '',
        lastName: '',
        email: '',
        password: ''
      },
      showLoginForm: true,
      showPasswordReset: false
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      }).then().catch(e => {
        if (e) {
          this.$notify({
            group: 'r318',
            title: 'Ha ocurrido un error',
            text: 'Por favor revisa tus datos e itenta de nuevo.',
            type: 'error',
            position: ['bottom', 'right']
          });
        }
      })
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name,
        lastName: this.signupForm.lastName
      }).then().catch(e => {
        if (e) {
          if (e && e.code === 'auth/email-already-in-use') {
            this.$notify({
              group: 'r318',
              title: 'Cuenta existente',
              text: 'El correo ingresado ya existe. Puedes recuperar tu cuenta haciendo click en el link "Recuperar contraseña"',
              type: 'error',
            });
          } else if (e && e.code === 'auth/weak-password') {
            this.$notify({
              group: 'r318',
              title: 'Contraseña invalida',
              text: 'Ingresa mínimo 6 caracteres para tu contraseña.',
              type: 'warn',
            });
          } else {
            this.$notify({
              group: 'r318',
              title: 'Ha ocurrido un error',
              text: 'Por favor revisa tus datos e itenta de nuevo.',
              type: 'error',
            });
          }
        }
      })
    }
  }
}
</script>
