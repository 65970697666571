<template>
  <div class="modal">
    <div class="modal-content">
      <div @click="$emit('close')" class="close">cerrar</div>
      <h3>Cambiar Contraseña</h3>
      <div v-if="!showSuccess">
        <p>Ingresa tu email</p>
        <form @submit.prevent>
          <input v-model.trim="email" type="email" placeholder="you@email.com" />
        </form>
        <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
        <button @click="resetPassword()" class="button">Enviar</button>
      </div>
      <p v-else>¡Muy Bien! Revisa tu email y haz click en el link.</p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>
